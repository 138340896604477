export default {
    data() {
        return {
            feature: [{
                title: '完备的智慧生态学习硬件装备',
                numberPic: require("../../assets/icons/num1.png"),
                text: '运用大数据，云计算和物联网技术，为中小学提供智慧生态装备，包括：智慧智慧生态水培箱，智慧生态育苗箱，智慧生态土培箱，智慧生态气象站，室外观测设备、各类生态传感器等。利用这些智能化的教具，培养同学们的创新学习思维和创新实践能力。通过智能化的设备，真实采集生态实验数据，开展了比较学习、探究学习和观察学习，激发了同学们的学习热情和学习兴趣。'
            }, {
                title: '有趣的智慧生态观测记录平台',
                numberPic: require("../../assets/icons/num2.png"),
                text: '通过学生对种/养殖的动植物进行生态观测，利用自己的肉眼观察、生态传感器的自动感知数据进行文字撰写、简笔画绘画、图片拍摄、传感器数据操作等方式，来进行生态笔记撰写，形成成长笔记，记录整个培育实践的过程，平台可以记录学生的操作轨迹、生态趋势数据、自动定时拍摄一键成片，最后可以生成和导出成长笔记，同时可以自动通过微信公众号、小程序、班牌，校园智慧屏幕分享。'
            }, {
                title: '完备的智慧生态课程学习平台',
                numberPic: require("../../assets/icons/num3.png"),
                text: '和学校拓展课程/课后服务课程等紧密结合，平台可以设置课程及相应指导教师，设置学生整班建制或者独立编组参与课程学习，可实现分班并分配指导教师，课程主题设置导学、作业、资源、讨论及考勤，其中作业练习教师可以实现拖拽组件自由组合，同时可关联学生成长评价，实现成长积分。'
            }, {
                title: '学习成果智慧呈现',
                numberPic: require("../../assets/icons/num4.png"),
                text: '学生的生态成长数据、生态数据趋势和实况视频信息，可以通过智慧屏幕系统及大数据技术，通过LED大屏幕、电视机、一体机、班牌、学习平台和移动端小程序来分享，教师和家长可以移动端参与点评和互动，师生家长的点赞和评价互动也可以根据动作绑定，触发评价指标，将评价计入学生成长积分中。'
            }, {
                title: '智慧生态学习和学生成长评价的融合',
                numberPic: require("../../assets/icons/num4.png"),
                text: '智慧生态学习是学校劳动实践教育及培养探究学习能力的重要环节，平台具备完整的评价体系，无论从德智体美劳全面评价维度还是仅从劳动教育实践维度，系统均可以自由设置各类评价标准，同时将各个互动及过程性学习环节关联到评价指标中，最后由系统自动生成学生学习画像或者个人成长画像。'
            }],
        }
    },
    created() {

    },
}